<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>订单管理</p>
			<i>/</i>
			<p>企业订单</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="100px">
						<el-form-item label="关键字：">
							<el-input class="input_medi" v-model="keyword" clearable placeholder="订单号/名称/资产内容"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="作废" value="-1"></el-option>
								<el-option label="待提交" value="1"></el-option>
								<el-option label="待技术确认" value="10"></el-option>
								<el-option label="待财务确认" value="15"></el-option>
								<el-option label="待工程验收" value="20"></el-option>
								<el-option label="工程验收完成" value="30"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="下单时间：" label-width="120px">
							<el-date-picker class="input_long" v-model="placeTime" value-format="yyyy-MM-dd HH:mm:ss"
								type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>企业订单列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="order_sn" label="订单号" align="left"></el-table-column>
				<el-table-column prop="create_time" label="下单时间" align="center"></el-table-column>
				<el-table-column prop="custom_name" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="order_content" label="资产内容" align="center"></el-table-column>
				<el-table-column prop="order_amount" label="订单金额" align="center"></el-table-column>
				<el-table-column prop="useful_life" label="时长(年)" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="orderr_status">
							<p v-if="scope.row.order_status == '0'" class="order_status_p1">作废</p>
							<p v-else-if="scope.row.order_status == '1'" class="order_status_p4">待提交</p>
							<p v-else-if="scope.row.order_status == '10'" class="order_status_p2">技术确认</p>
							<p v-else-if="scope.row.order_status == '15'" class="order_status_p2">财务确认</p>
							<p v-else-if="scope.row.order_status == '20'" class="order_status_p2">工程验收</p>
							<p v-else-if="scope.row.order_status == '30'" class="order_status_p3">工程验收完毕</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="buttom_wrap">
							<div class="buttom_wrap_div">
								<p v-if="scope.row.order_status >= '10' || scope.row.order_status == '0'" class="buttom_p" @click="modifyFn(scope.row)">详情</p>
								<p v-else class="buttom_p" @click="modifyFn(scope.row)">修改</p>
							</div>
							<div class="buttom_wrap_div" v-if="scope.row.order_status < '10' && scope.row.order_status != '0'">
								<el-popover v-model="scope.row.cancel_show" placement="left" width="240"
									trigger="click">
									<div>
										<el-input type="textarea" placeholder="作废备注:" maxlength="50" show-word-limit
											:autosize="{ minRows: 2, maxRows: 4}" :rows="2"
											v-model.trim="scope.row.cancel_mark">
										</el-input>
										<el-button style="margin: 10px 0 0 180px;" size="small" type="primary"
											@click="cancelFn(scope.row)">作废</el-button>
									</div>
									<p class="buttom_p" slot="reference">作废</p>
								</el-popover>
							</div>
							<div class="buttom_wrap_div" v-if="scope.row.order_status >= '10' && scope.row.order_status < '30'">
								<el-popover v-model="scope.row.confirm_show" placement="left" width="240"
									trigger="click">
									<div>
										<div v-if="scope.row.order_status == '20'" class="form_img_up">
											<nav class="nav_img">
												<li>
													<el-upload :action="$upImg" :on-remove="() => {onRemove(scope.row)}" ref="upload" :limit="1"
														:before-upload="beforeAvatarUpload" list-type="picture"
														:on-success="(response) => {uploadSuccess(response, scope.row)}" :data="imageData"
														:show-file-list="true">
														<div class="img_up_style">
															<p class="p3">验收单</p>
														</div>
													</el-upload>
												</li>
											</nav>
										</div>
										<el-input type="textarea" placeholder="确认备注:" maxlength="50" show-word-limit
											:autosize="{ minRows: 2, maxRows: 4}" :rows="2"
											v-model.trim="scope.row.confirm_mark">
										</el-input>
										<el-button style="margin: 10px 0 0 180px;" size="small" type="primary"
											@click="confirmFn(scope.row)">确认</el-button>
									</div>
									<p class="buttom_p" slot="reference">确认</p>
								</el-popover>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>


	</div>



</template>

<script>
	export default {
		name: 'enterpriseOrder',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '', // 状态
				placeTime: '', // 按下单时间搜索
				imageData: {
					ratio: "110x84,375x240",
					rootPath: "gg",
				}, // 上传图片时携带的参数和上传到哪里
				YSDImgServe: '', // 验收单
			}
		},
		components: {},
		created() {
			// 获取企业订单列表
			this.getOrderList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.placeTime = '';
				this.keyword = '';
				this.type = '';
				this.getOrderList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getOrderList();
			},

			// 获取企业订单列表
			getOrderList() {
				let start_time = '';
				let end_time = '';

				if (this.placeTime.length > 0) {
					start_time = this.placeTime[0];
					end_time = this.placeTime[1];
				}

				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}order/paging`,
					data: {
						page: this.currentPage1,
						keyword: this.keyword,
						start_time: start_time,
						end_time: end_time,
						order_status: this.type,
					}
				}).then(res => {
					if (res.code == '0') {
						this.total = res.count;
						this.listData = res.data.list.map(item => {
							this.$set(item, 'cancel_mark', '');
							this.$set(item, 'cancel_show', false);
							this.$set(item, 'confirm_mark', '');
							this.$set(item, 'confirm_show', false);
							this.$set(item, 'YSDImgServe', '');
							return item;
						});
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getOrderList();
			},
			
			// 删除文件时的钩子函数
			onRemove(row) {
				row.YSDImgServe = '';
			},
			// 上传前的钩子函数
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message({
						message: "上传图片大小不能超过 10MB!",
						type: "warning",
					});
					return;
				}
				return isLt2M;
			},
			
			// 图片上传成功
			uploadSuccess(response, row) {
				console.log(row)
				row.YSDImgServe = response.source;
			},

			// 作废
			cancelFn(row) {
				if (!row.cancel_mark) {
					this.$message({
						message: '作废备注不能为空',
						type: 'warning'
					});
					return;
				}

				this.$confirm('此操作将永久作废该条订单, 是否继续?', '确认提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$sendHttp({
						method: 'POST',
						url: `${process.env.VUE_APP_SAAS}order/cancel`,
						data: {
							order_sn: row.order_sn,
							mark: row.cancel_mark,
						}
					}).then(res => {
						if (res.code == '0') {
							this.$message({
								type: 'success',
								message: '作废成功!'
							});
							this.getOrderList();
						}
						else{
							this.$message.error('作废失败');
						}
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消作废'
					});
				});	
			},
			
			// 订单确认
			confirmFn(row){
				if(row.order_status == '20'){
					if(!row.YSDImgServe){
						this.$message({
							message: '请上传工程验收单',
							type: 'warning'
						});
						return;
					}
				}
				
				if (!row.confirm_mark) {
					this.$message({
						message: '确认备注不能为空',
						type: 'warning'
					});
					return;
				}
				
				this.$confirm('是否继续确认此订单', '确认提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$sendHttp({
						method: 'POST',
						url: `${process.env.VUE_APP_SAAS}order/approve`,
						data: {
							order_sn: row.order_sn,
							mark: row.confirm_mark,
							acceptance: row.YSDImgServe,
						}
					}).then(res => {
						if (res.code == '0') {
							this.$message({
								type: 'success',
								message: '确认成功!'
							});
							this.getOrderList();
						}
						else{
							this.$message.error('确认失败');
						}
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认'
					});
				});	
			},

			// 修改
			modifyFn(row) {
				// 是否可修改(已提交的订单不可修改)
				let disabledYes = 1;
				if(row.order_status >= '10' || row.order_status == '0'){
					disabledYes = 2;
				}
				this.$router.push({
					path: '/enterpriseOrderEdit',
					query: {
						id: row.order_sn,
						order_cat: '1',
						disabledYes,
					}
				});
			},

			// 新增
			addFn() {
				this.$router.push({
					path: '/enterpriseOrderEdit',
					query: {
						order_cat: '1',
						disabledYes: '1',
					}
				});
			},
		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 300px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

	.orderr_status {
		.order_status_p1 {
			font-size: 14px;
			color: red;
		}

		.order_status_p2 {
			font-size: 14px;
			color: #409EFF;
		}

		.order_status_p3 {
			font-size: 14px;
			color: #EE7B1D;
		}
		.order_status_p4{
			font-size: 14px;
			color: #67C23A
		}
	}

	.buttom_wrap {
		display: flex;
		justify-content: flex-start;

		.buttom_wrap_div {
			margin-right: 10px;

			.buttom_p {
				font-size: 14px;
				color: #EE7B1D;
				cursor: pointer;
			}
		}

	}
	
	.nav_img {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
		li {
			width: 134px;
			height: 134px;
			border: 1px dashed rgba(0, 0, 0, 0.2);
			background-color: rgba(0, 0, 0, 0.02);
			margin-right: 30px;
			position: relative;
	
			.img_up_style {
				width: 134px;
				height: 134px;
				.p3 {
					line-height: 134px;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.65);
				}
			}
		}
	}
	
	
	
</style>
